import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CookieConsent from 'react-cookie-consent';
import Header1 from '../components/header1';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({}));

const CookiePolicy = () => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Header1 title="COOKIE POLICY" />
        <p> Last updated 10.2.2020</p>
      </Container>
      <Container>
        <Grid container>
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <p>
              Vow ASA processes user statistics on the website. If you use our
              public web sites, we collect and process basic usage data. This
              includes your IP address, browser and operating system
              information, which pages you visit, source of referral, and date
              and time of use. This information is logged by the web server and
              the services mentioned below and may be used for analysing traffic
              patterns and system performance to maintain and improve our web
              service, security and advertisement.
            </p>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>COOKIES</h2>
            <p>
              Cookies are small text files that are placed on your computer when
              you download a webpage. You can decide if a website should be able
              to save such cookies in your browser by changing the settings.
              Learn more about cookies and how to control and disable cookies by
              visiting cookiesandyou.com
            </p>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>
              COOKIES AND TRACKING MECHANISMS IN USE
            </h2>
            <p>GOOGLE ANALYTICS</p>
            <p>
              Used to analyse how visitors use the website. The cookies are
              anonymous and cannot be linked directly to a particular person.
              <br />
              Google Analytics uses, among other things, the following cookies:
              _ga, _gid, _gat, among others. You can read more about these and
              what feature they have on Google Analytics’s web pages:
              <br />
              <a
                href="developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                target="_blanc"
              >
                developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
              </a>
            </p>
            <p>FACEBOOK</p>
            <p>
              Used to analyse traffic and collect data about users for
              personalization of marketing on Facebook. You can read more at:
              <br />
              <a href="facebook.com/full_data_use_policy" target="_blanc">
                facebook.com/full_data_use_policy
              </a>
            </p>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>IP ADDRESSES</h2>
            <p>
              IP addresses are obtained by anyone who visits our website. This
              is done to safeguard security, finding sources for faults and
              access control. IP addresses are only associated with individuals
              who provide us with personal information. Information can be used
              to compare the IP address and activity of the web pages.
            </p>
            <p>
              IP addresses are stored over a period that allows you to maintain
              good functionality and security. IP addresses that have been
              manually blocked due to security breaches will be stored as long
              as necessary to prevent further security breaches.
            </p>
          </Grid>
          <Grid item sm={6} />
        </Grid>

        <CookieConsent
          location="bottom"
          buttonText="Accept cookies"
          cookieName="myAwesomeCookieName2"
          style={{ background: 'rgb(25,25,25,0.78)' }}
          buttonStyle={{
            color: '#fff',
            backgroundColor: '#EC193E',
            padding: '8px 22px',
            fontSize: '12px',
          }}
          expires={150}
        >
          This website uses cookies. To learn more about how we use cookies see
          our <Link to="/cookie-policy">cookies policy</Link>
        </CookieConsent>
      </Container>
    </>
  );
};

export default CookiePolicy;
